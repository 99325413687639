<template>
  <div class="user-agreement">
    <h2>{{agreement.title}}</h2>
    <p class="update-time">更新日期：{{agreement.updateTime}}</p>
    <div class="tips pod">
      <h3>{{agreement.tipsTitle}}：</h3>
      <p>{{agreement.tips}}</p>
    </div>
    <div class="pod" v-for="(item,index) in agreement.content" :key="index">
      <h3>{{item.title}}</h3>
      <div class="case" v-for="(el,elIndex) in item.case" :key="elIndex">
        <h4>{{el.title}}</h4>
        <div class="row" :class="{'noflex':row.list}" v-for="(row,rowIndex) in el.row" :key="rowIndex">
          <i class="order" v-if="row.subheading">{{rowIndex + 1}}.</i>
          <span><i class="subheading" v-if="row.subheading">&nbsp;{{row.subheading}}：</i><span
              v-html="row.text"></span></span>
          <ul class="list">
            <li class="list-li" v-for="(li,liIndex) in row.list" :key="liIndex"><i class="dot">·</i>{{li}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import agreement from "./agreement.json"
  export default {
    data() {
      return {
        agreement: agreement
      }
    },
  }
</script>

<style lang="less" scoped>
  .user-agreement {
    // padding: 70px 151px 87px;
    width: 1200px;
    margin: 0 auto;
    padding: 70px 0 87px;

    // 公共样式
    h2,
    h3,
    h4 {
      color: #181818;
      font-weight: 600;
      line-height: 54px;
    }

    p {
      color: #444;
      font-size: 18px;
      line-height: 28px;
    }

    // 标题
    h2 {
      margin-bottom: 12px;
      font-size: 36px;
      text-align: center;
    }

    // 更新时间
    .update-time {
      color: #444;
      text-align: right;
      font-size: 18px;
      line-height: 28px;
    }

    // 特别提示
    .tips {
      h3 {
        font-size: 24px;
        margin-bottom: 7px;
      }
    }

    // 正文
    .pod {
      margin-bottom: 37px;

      h3 {
        font-size: 30px;
        margin-bottom: 18px;
      }

      h4 {
        margin-bottom: 7px;
        font-size: 24px;
      }

      .case:last-child {
        margin-top: 18px;
      }

      .row {
        display: flex;
        margin-bottom: 3px;

        &.noflex {
          display: block;
        }

        i.order,
        i.subheading,
        i.dot {
          color: #181818;
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
        }

        i.dot {
          margin-right: 5px;
        }

        span {
          color: #444;
          font-size: 18px;
          line-height: 28px;
        }

        .list-li {
          color: #444;
          font-size: 18px;
          line-height: 28px;
          text-indent: 2em;

          .dot {
            margin-right: 5px;
          }

        }

        /deep/ a {
          color: #3e6eff;

          &:hover {
            text-decoration: underline;
          }
        }

      }
    }
  }
</style>