var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-agreement" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.agreement.title))]),
      _c("p", { staticClass: "update-time" }, [
        _vm._v("更新日期：" + _vm._s(_vm.agreement.updateTime)),
      ]),
      _c("div", { staticClass: "tips pod" }, [
        _c("h3", [_vm._v(_vm._s(_vm.agreement.tipsTitle) + "：")]),
        _c("p", [_vm._v(_vm._s(_vm.agreement.tips))]),
      ]),
      _vm._l(_vm.agreement.content, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "pod" },
          [
            _c("h3", [_vm._v(_vm._s(item.title))]),
            _vm._l(item.case, function (el, elIndex) {
              return _c(
                "div",
                { key: elIndex, staticClass: "case" },
                [
                  _c("h4", [_vm._v(_vm._s(el.title))]),
                  _vm._l(el.row, function (row, rowIndex) {
                    return _c(
                      "div",
                      {
                        key: rowIndex,
                        staticClass: "row",
                        class: { noflex: row.list },
                      },
                      [
                        row.subheading
                          ? _c("i", { staticClass: "order" }, [
                              _vm._v(_vm._s(rowIndex + 1) + "."),
                            ])
                          : _vm._e(),
                        _c("span", [
                          row.subheading
                            ? _c("i", { staticClass: "subheading" }, [
                                _vm._v(" " + _vm._s(row.subheading) + "："),
                              ])
                            : _vm._e(),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(row.text) },
                          }),
                        ]),
                        _c(
                          "ul",
                          { staticClass: "list" },
                          _vm._l(row.list, function (li, liIndex) {
                            return _c(
                              "li",
                              { key: liIndex, staticClass: "list-li" },
                              [
                                _c("i", { staticClass: "dot" }, [_vm._v("·")]),
                                _vm._v(_vm._s(li)),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }